import './index.css';
import React from 'react';
import { render, hydrate } from 'react-dom';
import ErrorBoundary from './errorBoundary';
import App from './base/app/App';
import * as serviceWorker from './serviceWorker';
import { PostHogProvider } from 'posthog-js/react';
import './i18n';
import Utils from './base/Utils';

if (typeof window.posthogConfig !== 'undefined') {
    Utils.setCookie('posthogConfig', JSON.stringify(window.posthogConfig), 365);
}

var cookiePHConfig = Utils.getCookie('posthogConfig');
if (cookiePHConfig) {
    cookiePHConfig = JSON.parse(cookiePHConfig);
}

if (!cookiePHConfig && typeof cookiePHConfig !== 'object') {
    cookiePHConfig = {};
}

const options = Object.assign({
    api_host: process.env.REACT_APP_POSTHOG_HOST,
    autocapture: false,
    disable_session_recording: true
}, cookiePHConfig);

var location = window.location;
//apm.setInitialPageLoadName((location.pathname + location.search + location.hash).trim());

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    hydrate(<React.StrictMode><ErrorBoundary><PostHogProvider
        apiKey={process.env.REACT_APP_POSTHOG_ID}
        options={options}><App /></PostHogProvider></ErrorBoundary></React.StrictMode>, rootElement);
} else {
    render(<React.StrictMode><ErrorBoundary><PostHogProvider
        apiKey={process.env.REACT_APP_POSTHOG_ID}
        options={options}><App /></PostHogProvider></ErrorBoundary></React.StrictMode>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
